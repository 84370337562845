import React, { ChangeEvent, useEffect, useState } from 'react';
import { Collapse, Table,
   Form, Button, Select, DatePicker, Modal, Progress, Input, message } from 'antd';
import { useAppDispatch } from '../../configs/hook';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {getStudent, studentSlice, importCourse} from './studentSlice';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import axios from 'axios';
import type { SelectProps } from 'antd';
import { updateCourse } from './studentSlice';

const { Panel } = Collapse;

const Student = (props:{taxCode:string|null}) => {
  const [courseName, setCourseName] = useState("");
  const [form] = Form.useForm();
  const handleOk = () => {
    dispatch(updateCourse({malophocphan:malophocphan,
    tenlophocphan:tenlophocphan,
    thoiluong:thoiluong,
    startDate:startDate,
    endDate:endDate
    }))
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [malophocphan, setMalophocphan] = useState('');
  const [tenlophocphan, setTenlophocphan] = useState('');
  const [thoiluong, setThoiluong] = useState(5);
  const [fileList, setFileList] = useState<File>();
  const [subjectId, setSubjectId] = useState(0);
  const [firstDay, setFirstDay] = useState(1);
  const [secondDay, setSecondDay] = useState(1);
  const [subjectName, setSubjectName] = useState('Tổng quan');
  const calculatePercent = (startDate:Date, endDate:Date) => {
    let currentDate = new Date();
    let totalDays = (currentDate.getTime() - startDate.getTime())/(1000*60*60*24);
    let numberOfDays = (endDate.getTime()-startDate.getTime())/(1000*60*60*24);
    return (totalDays/numberOfDays)*100 > 100 ? 100: Math.round((totalDays/numberOfDays)*100)
  }
  const onFinish = async (values: any) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
      if(fileList)
      {
        formData.append("MyFile", fileList);
        formData.append("CourseName", values.CourseName);
        let myStartMonth = (startDate.getMonth()+1)<10?"0"+(startDate.getMonth()+1):startDate.getMonth()+1;
        let myStartDate = startDate.getDate()<10?"0"+startDate.getDate():startDate.getDate();
        let myEndMonth = (endDate.getMonth()+1)<10?"0"+(endDate.getMonth()+1):endDate.getMonth()+1;
        let myEndDate = endDate.getDate()<10?"0"+endDate.getDate():endDate.getDate();
        formData.append("StartDate", startDate.getFullYear() + '-' + myStartMonth + '-' + myStartDate);
        formData.append("EndDate", endDate.getFullYear() + '-' + myEndMonth +'-'+ myEndDate);
        formData.append("SubjectId", values.SubjectId.toString());
        formData.append("FirstDay", values.FirstDay);
        formData.append("SecondDay", values.SecondDay);
        console.log(values.SubjectId);
        const config = {     
          headers: { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` }
        }
        axios.post(process.env.REACT_APP_API_URL+'api/ImportFromExcel', formData, config)
          .then(response => {
            if(response.status==401){
              message.info("Phiên đăng nhập đã hết hạn!");
              localStorage.clear();
              window.location.reload();
          }
                let myFileName: string = fileList?.name.substring(0,fileList?.name.lastIndexOf('.'));
                console.log(response.data);
                dispatch(importCourse(myFileName));

          })
          .catch(error => {
            if(error.response)
              console.log(error.response);
            else if(error.request){
              console.log("Loi 2");
            }
            else
              console.log("Loi 3");
          });       
      };
    }
  type subjectType = {
    id: number,
    tenmonhoc:string,
    abbreation:string
  }
  type OptionsType = {
    value: number,
    label: string
  }
  const [options, setOptions] = useState<SelectProps['options']>([]);
  const [subject, setSubject] = useState<subjectType[]>([]);
  const [days, setDays] = useState<SelectProps['options']>([
    {value: 2, label: 'Thứ hai'},
    {value: 3, label: 'Thứ ba'},
    {value: 4, label: 'Thứ tư'},
    {value: 5, label: 'Thứ năm'},
    {value: 6, label: 'Thứ sáu'},
    {value: 7, label: 'Thứ bảy'}
  ]);
  const [dataOptions, setDataOptions] = useState<OptionsType[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().getTime()+90*24*60*60*1000));
  const columns = [
    {
      title: 'Mã số sinh viên',
      dataIndex: 'masinhvien',
      key: 'masinhvien',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'hovaten',
      key: 'hovaten',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'ngaysinh',
      key: 'ngaysinh',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tùy chọn',
      dataIndex: 'resetPass',
      key: 'resetPass',
      render: (key:Number, record:any, index:Number) => {
        return(
        <>
        <Button type="primary"
        disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
        false : true } 
        onClick = {()=>{
          const token = localStorage.getItem('token');
          const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/User', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            body: JSON.stringify({role:"1", userName:record.email, oldPassword:"", 
            newPassword:'abc123!', mustChangeAtFirstTime: true})
        }).then(response => response.json())
        .then((data)=>{
              message.info("Mật khẩu đã được reset về mặc định abc123!");
        })
        .catch(err => console.log(err));
        }}
        >Reset mật khẩu</Button>
        <Button type="primary"
        disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
        false : true } 
        onClick = {()=>{         
        }}
        >Vô hiệu</Button>
        </>
        ) 
    }
  }
  ];
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  const dispatch = useAppDispatch();
  const [emailData, setEmailData] = useState(localStorage.getItem('emailData'));
  const myCourses = useSelector((state:RootState)=>state.student);
  const handleChange = (value: number) => {
    setSubjectId(value);
  };
  const handleChange1 = (value: number) => {
    setFirstDay(value);
  };
  const handleChange2 = (value: number) => {
    setSecondDay(value);
  };
  useEffect(()=>{
    getAllSubjects();
  },[])
  const getAllSubjects = async () => {
    const token = localStorage.getItem('token');
    if(props.taxCode=="admin@alta.com.vn"){
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{
      setOptions(data.map((item:any)=>{
        return {value: item.id, label: item.taxCode=='admin@alta.com.vn' ? item.tenmonhoc : item.title}
      }))
      setSubject(data.map((item:any)=>{
        return {id: item.id, tenmonhoc: item.taxCode=='admin@alta.com.vn' ? item.tenmonhoc : item.title, abbreation: item.abbreation}
      }))
      console.log(options);
})
.catch(err => console.log(err));
    }
    else{
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject/enterprise/'+props.taxCode, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{
      setOptions(data.map((item:any)=>{
        return {value: item.id, label: item.tenmonhoc}
      }))
      setSubject(data.map((item:any)=>{
        return {id: item.id, tenmonhoc: item.tenmonhoc, abbreation: item.abbreation}
      }))
      console.log(options);
})
.catch(err => console.log(err));
    } 
  }
  return (
    <>
    <div>
    <Form onFinish={onFinish}
    disabled = {localStorage.getItem('emailData') != 'test@alta.com.vn' ? 
    false : true }
    form = {form}
    layout="inline"
    >
      <FormItem name="fileList">
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else{
            setFileList(temp[0]);
            form.setFieldsValue({
              CourseName: props.taxCode == 'admin@alta.com.vn'?subject.filter(x=>x.abbreation == temp[0].name.substring(0,3))[0].tenmonhoc +
              " " + temp[0].name.substring(temp[0].name.lastIndexOf("_")+1, temp[0].name.lastIndexOf(".")) + " năm " + 
              temp[0].name.substring(temp[0].name.indexOf("_")+1, 8)
              :subject.filter(x=>x.id == Number(temp[0].name.substring(0,3)))[0].tenmonhoc +
              " " + temp[0].name.substring(temp[0].name.lastIndexOf("_")+1, temp[0].name.lastIndexOf(".")) + " năm " + 
              temp[0].name.substring(temp[0].name.indexOf("_")+1, 8),
              SubjectId: props.taxCode == 'admin@alta.com.vn' ? subject.filter(x=>x.abbreation == temp[0].name.substring(0,3))[0].id
              : subject.filter(x=>x.id == Number(temp[0].name.substring(0,3)))[0].id
            })
          }            
          }} />
      </FormItem>
      <Form.Item name="CourseName" 
      label='Tên lớp học phần:'
      style={{ width: "70%", marginRight: 0 }}
      rules={[{required:true, message:'Tên lớp học phần không được để trống'}]}
      >
        <Input value={courseName} onChange={(e)=>setCourseName(e.currentTarget.value)} />
      </Form.Item>
      <Form.Item name="SubjectId" 
      label='Chọn môn học:'
      style={{ width: "40%", marginRight: 20 }}
      rules={[{required:true, message:'Phải chọn môn học'}]}
      >
        <Select
        onChange={handleChange}
        options={options}
        />
      </Form.Item>
      <Form.Item name="FirstDay" 
      label='Buổi 1:'
      style={{ width: "30%", marginRight: 20 }}
      rules={[{required:true, message:'Phải chọn ngày học'}]}
      >
        <Select
        onChange={handleChange1}
        options={days}
        />
      </Form.Item>
      <Form.Item name="SecondDay" 
      label='Buổi 2:'
      style={{ width: "30%", marginRight: 20 }}
      rules={[{required:true, message:'Phải chọn ngày học'}]}
      >
        <Select
        onChange={handleChange2}
        options={days}
        />
      </Form.Item>      
      <FormItem name="StartDate" label="Ngày bắt đầu:">
      <DatePicker defaultValue={dayjs(startDate)}
        onChange={(date, dateString)=>{
          setStartDate(new Date(dateString.toString()));
        }} />
      </FormItem>
      <FormItem name="EndDate" label="Ngày kết thúc:">
      <DatePicker defaultValue={dayjs(endDate)}
        onChange={(date, dateString)=>{
          setEndDate(new Date(dateString.toString()));
        }} />
      </FormItem>
      <Form.Item label="">
          <Button type="primary" htmlType="submit">Lưu dữ liệu</Button>
        </Form.Item>
    </Form>
    </div>
    <Collapse onChange={onChange}>
    {myCourses.map((item, index)=>{
        return(
        <Panel header={<div>
          {item.tenlophocphan}&nbsp;&nbsp;&nbsp;
          {localStorage.getItem('emailData') != 'test@alta.com.vn' ?
          <>
          <a onClick={()=>{
            setIsModalOpen(true);
            setStartDate(new Date(item.startDate));
            setEndDate(new Date(item.endDate));
            setMalophocphan(item.malophocphan);
            setTenlophocphan(item.tenlophocphan);
            setThoiluong(item.thoiluong);
          }}>Cập nhật thông tin</a>
          &nbsp;
          <a
            onClick={async()=>{
              const token = localStorage.getItem('token');
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Course/'+ item.malophocphan, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const msg = await rawResponse.json();
        message.info(msg.message);
              dispatch(getStudent("admin"));
            }}
          >Xóa nhóm thực tập</a>
          </>
          :<>
          </>}
          {calculatePercent(new Date(item.startDate), new Date(item.endDate)) < 100 ?
          <Progress percent={calculatePercent(new Date(item.startDate), new Date(item.endDate))}
          status="active" />
          : <Progress percent={100}/>}
          </div>
          } key={index}>
            <Table dataSource={item.students} columns={columns} />
        </Panel>
        )
    })}
    </Collapse>
    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        Ngày bắt đầu: &nbsp;<DatePicker defaultValue={dayjs(startDate)} disabled
        onChange={(date, dateString)=>{
          setStartDate(new Date(dateString.toString()));
        }}
        />&nbsp;&nbsp;Ngày kết thúc:&nbsp;
        <DatePicker defaultValue={dayjs(endDate)} disabled
        onChange={(date, dateString)=>{
          setEndDate(new Date(dateString.toString()))
        }}
        />
        Thời lượng:<Input type='number' value={thoiluong} onChange={(e)=>setThoiluong(Number(e.currentTarget.value))} /><br />
        Tên lớp học phần: <Input type="text" value={tenlophocphan} onChange={(e)=>setTenlophocphan(tenlophocphan)} />
    </Modal>
    </>
  )
}
export default Student;