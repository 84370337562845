import React, { useState, useEffect } from 'react';
import { Button, Modal, message, Radio, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Question } from '../../interface';
import ReactHtmlParser from 'react-html-parser';
import '../thitracnghiem/style.css';
import { useAppDispatch } from '../../configs/hook';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import ReactQuill from 'react-quill';
import {getQuestionsByType, countQuestion} from '../listQuestion/questionSlice';
import { AddQuestion } from '../addQuestion';
import UpdateQuestion from '../updateQuestion';
import axios from 'axios';
import './style.css';
const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };
  
type QuestionProps = {
  id:number
}
  
export const ListQuestion = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionsId, setQuestionId] = useState(0);
    const [isModalOpenEditable, setIsModalOpenEditable] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [myQuestion, setMyQuestion] = useState<Question>();
    const [value, setValue] = useState<number|null>(null);
    const dispatch = useAppDispatch();
    const [questionContent, setQuestionContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const showModalEditable = () => {
      setIsModalOpenEditable(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleOkEditable = () => {
      const token = localStorage.getItem('token');
      fetch(process.env.REACT_APP_API_URL + "api/Cauhoi/content/"+ questionsId + "/" + value, {
        method: 'PUT', // or 'PUT' depending on your requirement
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(questionContent)
      })
      .then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
      })
      .then(data => {
        message.info("Cập nhật thành công");
        let id = Number(localStorage.getItem("monhocid"));
        dispatch(getQuestionsByType(id, rowsPerPage,currentPage));
      })
      .catch(error => {
        console.error('Error:', error);
      });
      setIsModalOpenEditable(false);
    };
    const handleUpdateOk = () => {
      setIsUpdateModalOpen(false);
    }
    const handleCancelEditable = () => {
      setIsModalOpenEditable(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const handleUpdateCancel = ()=>{
      setIsUpdateModalOpen(false);
    }
    const myQuestions = useSelector((state:RootState)=>state.questions);
    const [needUpdate, setNeedUpdate] = useState(false);
    const totalItems = useSelector((state:RootState)=>state.questionCount);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(2);
    const handleUpdateQuestion = (status:boolean) => {
        setNeedUpdate(!needUpdate);
    }
    const [fileList, setFileList] = useState<File>();
    const onFinishImport = async (values: any) => {
      const token = localStorage.getItem('token');
      const formData = new FormData();
        if(fileList)
        {
          formData.append("MyFile",fileList); 
          let monhocid = localStorage.getItem("monhocid");
          if(monhocid!=null){
            formData.append("Loaicauhoi", monhocid);
          }                               
          const  myheaders = { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` };          
          axios.post(process.env.REACT_APP_API_URL+'api/Cauhoi/importQuestion', formData, {
            headers: myheaders})
            .then(response => {
              setIsLoading(false);
            })
            .catch(error => {
              setIsLoading(false);
            });       
        };
      }
    useEffect(()=>{
        let id = Number(localStorage.getItem("monhocid"));
        dispatch(countQuestion(id));
        dispatch(getQuestionsByType(id, rowsPerPage, 1));
      },[localStorage.getItem("monhocid")])
      useEffect(()=>{
        let id = Number(localStorage.getItem("monhocid"));
        dispatch(getQuestionsByType(id, rowsPerPage,currentPage));
      },[currentPage, rowsPerPage])
    return(
    <>
      <div className="question">Số câu hỏi: <select onChange={(e)=>{
        setRowsPerPage(Number(e.currentTarget.value))
      }} value={rowsPerPage}>
        <option value={2}>2</option>
        <option value={5}>5</option>
        <option value={10}>10</option>
        </select>&nbsp;/ Trang &nbsp;&nbsp;&nbsp;
        <button onClick={showModal}>
            Thêm câu hỏi
        </button>
        <Form onFinish={onFinishImport}
    layout="inline"
    >
      <Form.Item name="fileList">
        <Input type="file" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else{
            setFileList(temp[0]);
          }            
          }}/>
      </Form.Item>    
      <Form.Item label="">
          <Button type="primary" onClick={()=>setIsLoading(true)} htmlType="submit">Import</Button>
      </Form.Item>
    </Form>
        </div>
        {myQuestions.map((item:any)=>
            <div key={item.questionsId} className="question">
              <Button style={{marginTop:'0px', marginRight:'0px', float:'right'}}
              onClick={()=>{
                setMyQuestion({
                  QuestionsId: item.questionsId,
                  Phatbieu: item.phatbieu,
                  Nhieuluachon: item.nhieuluachon,
                  Loaicauhoi: item.loaicauhoi,
                  ImageDataUrl: item.imageDataUrl,
                  Choices: item.choices
                });
                setIsUpdateModalOpen(true);
              }}
              >Cập nhật ảnh</Button>
              <Button style={{marginTop:'0px', marginRight:'0px', float:'right'}} onClick={async (e)=>{
                const token = localStorage.getItem('token');
                const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/cauhoi/'+item.questionsId, {
                  method: 'DELETE',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // notice the Bearer before your token
                  },
                });
                await dispatch(countQuestion(Number(localStorage.getItem("monhocid"))));
                dispatch(getQuestionsByType(Number(localStorage.getItem("monhocid")), rowsPerPage,currentPage));
              }}>Xóa</Button>
              {item.imageDataUrl!=""?<img src={item.imageDataUrl} style={{width:'500px', height:'350px'}}/>
              :<p></p>
            }
            <p>
                {ReactHtmlParser(item.phatbieu)} &nbsp; 
                <a onClick={()=>{
                    setQuestionContent(item.phatbieu);
                    setQuestionId(item.questionsId);
                    setValue(item.dokho);
                    setIsModalOpenEditable(true);
                }}>Edit</a>
            </p>
            {item.nhieuluachon ? item.choices?.map((a:any)=>{           
            return(
            <p>
            <input type="checkbox" key={a.choicesId} 
            defaultChecked={a.dapan}
            />&nbsp;&nbsp;&nbsp;{a.phatbieu}</p>)
          })
            :       
            item.choices?.map((a:any)=>{
              return(
                <p>
                <input type="radio" key={a.choicesId}
                defaultChecked={a.dapan}                
                value={a.choicesId} />{ReactHtmlParser(a.phatbieu)}
                </p>
              )})
            }
            </div>        
            )}
            <Pagination style={{margin: '10px 50px 10px 50px'}} total={totalItems} 
            pageSize={rowsPerPage}
            onChange={(page, pageSize)=>{
              setCurrentPage(page);
              //setCurrentPageElements(myQuestions.slice(2*page - 2, 2*page));
            }}
            itemRender={itemRender} />;
            <Modal title="Thêm câu hỏi" open={isModalOpen} width={800}
            onOk={handleOk} onCancel={handleCancel}>
              <AddQuestion handleFinish={(e)=>{setIsModalOpen(e);
                setNeedUpdate(!needUpdate);  
            }} />
            </Modal>
            <Modal title="Cập nhật câu hỏi" open={isModalOpenEditable} width={800}
            onOk={handleOkEditable} onCancel={handleCancelEditable}>
        <Radio.Group onChange={(e)=>setValue(e.target.value)} value={value}>
            <Radio value={0}>Lý thuyết</Radio>
            <Radio value={1}>Có vận dụng và suy luận</Radio>
            <Radio value={2}>Có code và suy luận</Radio>
            <Radio value={3}>Nâng cao</Radio>
        </Radio.Group>
                <ReactQuill
        value={questionContent}
        onChange={(value)=>{
          setQuestionContent(value)
        }}
        modules={{
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent'
        ]}
      />           
            </Modal>
            <Modal title="Cập nhật ảnh cho câu hỏi" open={isUpdateModalOpen} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
              <UpdateQuestion Phatbieu={myQuestion?.Phatbieu}
              QuestionsId = { myQuestion?.QuestionsId} 
              Nhieuluachon= {myQuestion?.Nhieuluachon}
              Loaicauhoi= {myQuestion?.Loaicauhoi}
              ImageDataUrl= {myQuestion?.ImageDataUrl}
              Choices = {myQuestion?.Choices}
              UpdateEvent={handleUpdateQuestion}
              />
            </Modal>
      <Modal title="Loading" open={isLoading} closeIcon={null} footer={null}>
      <div className='LoadingContainer'>
        <img src='./images/loading.gif' />
      </div>
    </Modal>
    </>
    )
}
