import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { getCurrentWeek } from '../../helpers';
import { stat } from 'fs';
import type { Dayjs } from 'dayjs';

type itemTypes = {id:string, content: string}
type listWeekDayTaskType = {
    weekday: string, data: itemTypes[]
}[]

const listWeekDayTask = [
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
   
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
  
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
  
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
  
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
  
    [{weekday: 'monday', data: [] as itemTypes[]},
    {weekday: 'tuesday', data: [] as itemTypes[]},
    {weekday: 'wednesday', data: [] as itemTypes[]},
    {weekday: 'thursday', data: [] as itemTypes[]},
    {weekday: 'friday', data: [] as itemTypes[]},
    {weekday: 'saturday', data: [] as itemTypes[]}],
    ]
export const timelineSlice = createSlice({
    name:'timeline',
    initialState:listWeekDayTask,
    reducers: ({
        getTimeline: (state, action: PayloadAction<listWeekDayTaskType[]>) => {
            state = [];
            return action.payload;
        }
    })
})
export function getTimeline(data:Dayjs){
    return async function getTimelineThunk(dispatch: Dispatch, getState:any){
        const token = localStorage.getItem('token');
        const myEmail = localStorage.getItem('userNameData');
        let myNewArrLst = [] as listWeekDayTaskType[];
        let stDate = await getCurrentWeek(data)[0].getFullYear() + '-'
  + (getCurrentWeek(data)[0].getMonth()+1<10?'0'+ (getCurrentWeek(data)[0].getMonth()+1)
  :getCurrentWeek(data)[0].getMonth()+1)+'-'
  + (getCurrentWeek(data)[0].getDate()<10?'0'+getCurrentWeek(data)[0].getDate()
  :getCurrentWeek(data)[0].getDate());
  let edDate = await getCurrentWeek(data)[5].getFullYear() + '-'
  + (getCurrentWeek(data)[5].getMonth()+1<10?'0'+ (getCurrentWeek(data)[5].getMonth()+1)
  :getCurrentWeek(data)[5].getMonth()+1)+'-'
  + (getCurrentWeek(data)[5].getDate()<10?'0'+getCurrentWeek(data)[5].getDate()
  :getCurrentWeek(data)[5].getDate());
        fetch(process.env.REACT_APP_API_URL+'api/ScheduleTask/'+stDate+'/'+edDate + '/' + myEmail, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
      }).then(response => response.json())
      .then((data)=>{
          myNewArrLst = [];
          for(let i=0; i<=5; i++){
            myNewArrLst.push(setValue(i, data));
          }
      }).then(async ()=>{
        await dispatch(timelineSlice.actions.getTimeline(myNewArrLst))}
      )
    }
    }
    export const setValue = (i:number, data:any) => {
        let dayPart1 = data.filter((x:any)=>x.dayPart == 'Ca ' + (i+1));
        let myNewList = listWeekDayTask[i];
        if(dayPart1 == null)
          myNewList = [{weekday: 'monday', data: [] as itemTypes[]},
          {weekday: 'tuesday', data: [] as itemTypes[]},
          {weekday: 'wednesday', data: [] as itemTypes[]},
          {weekday: 'thursday', data: [] as itemTypes[]},
          {weekday: 'friday', data: [] as itemTypes[]},
          {weekday: 'saturday', data: [] as itemTypes[]}]
        else{
          let mondayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==1)
          let tuesdayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==2)
          let wednesdayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==3)
          let thursdayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==4)
          let fridayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==5)
          let saturdayTasks = dayPart1.filter((x:any)=>(new Date(x.taskDate)).getDay()==6)
          myNewList = [{weekday: 'monday', data: mondayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          }) },
          {weekday: 'tuesday', data: tuesdayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          })},
          {weekday: 'wednesday',data: wednesdayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          })},
          {weekday: 'thursday', data: thursdayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          })},
          {weekday: 'friday', data: fridayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          })},
          {weekday: 'saturday', data: saturdayTasks.map((item:any)=>{
            return(
              {id:item.id, content:item.taskName}
            )
          })}]
        }
        return myNewList;
      }
