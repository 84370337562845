import React, {ChangeEvent, useEffect, useState } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Select, SelectProps,
   Radio, RadioChangeEvent, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import './styles.css';
import axios from 'axios';
import { ExcelType, ExcelAllType, InternType } from '../../interface';
import { Excel } from "antd-table-saveas-excel";
import type { TableRowSelection } from 'antd/es/table/interface';
import { isMappedTypeNode, isTypeNode } from 'typescript';
import { useSelector } from 'react-redux';
import type { SearchProps } from 'antd/es/input';
import { RootState } from '../../redux/store';
import EnableRegister from '../enableRegister';
import EditCv from '../editCv';
import { resolve } from 'path';
const signalR = require('@microsoft/signalr');
const { Search } = Input;
const InternsRegistered = () => {
  const [isDisabled, setIsDisabled] = useState(false);
    const [connection, setConnection] = useState(new signalR.HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL+"realtimehub")
    .build());
    const [data, setData] = useState<InternType[]>([]);
    const [valueReady, setValueReady] = useState(0);
    const token = localStorage.getItem('token');
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [subjectId, setSubjectId] = useState(-1);
    const [status, setStatus] = useState<string>("Tất cả");
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isConfirmModalOpen1, setIsConfirmModalOpen1] = useState(false);
    const [isConfirmModalOpen2, setIsConfirmModalOpen2] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteItem, setDeleteItem] = useState<number[]>([]);
    const [courseCode, setCourseCode] = useState('');
    const [dataExport, setDataExport] = useState<ExcelType[]>([]);
    const [dataExportAll, setDataExportAll] = useState<ExcelAllType[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const universities = useSelector((state:RootState)=>state.university);
    const admins = useSelector((state:RootState)=>state.admins)
    const myMailPattern = useSelector((state:RootState)=>state.mailPattern);
    const [content, setContent] = useState(myMailPattern[0]?.content);
    const [title, setTitle] = useState(myMailPattern[0]?.title);
    const [subject, setSubject] = useState(myMailPattern[0]?.subject);
    const [greeting, setGreeting] = useState(myMailPattern[0]?.greeting);
    const [footer, setFooter] = useState(myMailPattern[0]?.footer);
    const [selectedRows, setSelectedRows] = useState<InternType[]>([]);
    const rowSelection = {
      selectedRowKeys,
      onChange: (selectedRowKeys: React.Key[], selectedRows:InternType[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        setSelectedRowKeys(selectedRowKeys);
        setDataExportAll(selectedRows.map((item:InternType, index:number)=>{
            return {
              stt: index+1,
              id: item.id,
              internName: item.internName,
              dateOfBirth: item.dateOfBirth,
              internMail:item.internMail,
              university:item.university,
              telephoneNum:item.telephoneNum,
              internSpecialized:item.internSpecialized,
              major:item.major,
              internable: item.internable,
              citizenIdentification:item.citizenIdentification,
              citizenIdentificationDate:item.citizenIdentificationDate,
              entranceTest:item.entranceTest
            };
        }));
        setDataExport(selectedRows.map((item:InternType, index:number)=>{
          return {
            stt: index+1,
            masv:courseCode+"_0"+(index+1),
            holot: item.internName.substring(0, item.internName.lastIndexOf(' ')),
            ten: item.internName.substring(item.internName.lastIndexOf(' ')),
            ngaysinh: item.dateOfBirth,
            gioitinh: "Nam",
            matruong:universities.filter(x=>x.tentruong == item.university)[0].matruong,
            email:item.internMail
          };
      }));
      setDeleteItem(selectedRows.map((item:InternType, index:number)=>{
        return item.id
    }));
        setSelectedRows(selectedRows);
      },
    };
    const [totalRecords, setTotalRecords] = useState(0);
    const getTotalRecords = async (value: number, status: string, ready:number) => {
      await fetch(process.env.REACT_APP_API_URL+'api/Register/total/' + status + "/" 
        + value + '/' + ready, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then((data)=>{
    setTotalRecords(Number(data));
});
}
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const customPagination = {
      current: currentPage,          // Current page number
      pageSize: pageSize,        // Number of items per page
      total: totalRecords,          // Total number of items
      showSizeChanger: true,
      pageSizeOptions: ['50', '100'], // Optional: Page size options
      // You can also specify other pagination properties like showSizeChanger, showTotal, etc.
      onChange: async (page:number) => {
        // Handle the page change event
        setCurrentPage(page);
        setIsLoading(true);
        // You can perform additional actions here, such as fetching data for the new page
        await getData(searchText,subjectId, status, page, pageSize, valueReady);
        setIsLoading(false);
      },
      onShowSizeChange: async(current:number, newSize:number) => {
        // Handle the page size change event
        setPageSize(newSize);
        setCurrentPage(current)
        setIsLoading(true);
        await getData(searchText, subjectId, status, current, newSize, valueReady);
        setIsLoading(false);
      },
    };
    const getData = async (searchText: string, value: number, status: string, pageNumber: number, pageSize: number, ready: number) => {
      await fetch(process.env.REACT_APP_API_URL+'api/Register/internbystatus/' + searchText + "/" + status + "/" + value
      + "/" + pageNumber + "/" + pageSize + "/" + ready, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(async(data)=>{
  let tempData: InternType[] = [];
  await data.map((item:any)=>{
    tempData.push({
      key: item.id,
      id: item.id,
      internName: item.internName,
      internMail:item.internMail,
      dateOfBirth:item.dateOfBirth,
      university: universities.filter(x=>x.matruong==item.university)[0]!=null?
      universities.filter(x=>x.matruong==item.university)[0].tentruong:'',
      fileUrl:item.fileUrl,
      internSpecialized:item.internSpecialized,
      telephoneNum: item.telephoneNum,
      internable: item.internable==1?'Thực tập':'Chính thức',
      fullTime: item.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: item.major,
      registeredDate: item.registeredDate,
      citizenIdentification: item.citizenIdentification,
      citizenIdentificationDate: item.citizenIdentificationDate,
      isEditableInternSpecialized:false,
      isEditableUniversity:false,
      internStatus: item.internStatus,
      readyToWork: item.readyToWork == true?1:2,
      yearOfExperiences: 0,
      foreignLanguage: 'IELTS from 8.0',
      entranceTest: item.entranceTest,
      interviews: item.interviews,
      note:item.note
    })
  })      
  setData(tempData);            
})
.catch(err => console.log(err));
if(value!=-1)
{
await fetch(process.env.REACT_APP_API_URL+'api/Course/getcourecode/'+value, {
  method: 'GET',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
  },
}).then(response => response.json())
.then((data)=>{
    //message.info(data.message);      
    setCourseCode(data.message);
    setIsExportExcel(true);
})
.catch(err => console.log(err));
}
else
setIsExportExcel(false);
}
    const handleChange = async (value: number) => {
      setIsLoading(true);
      await Promise.all([getTotalRecords(value, status, valueReady),
      getData(searchText, value, status, 1, pageSize, valueReady)])
      setIsLoading(false);
      setSubjectId(value); setCurrentPage(1);
    }
    const handleChange1 = async (value: number) => {
      let mystatus:string = value==1?"Tất cả":value==2?"Đã đăng ký":value==3?"Đã trả hồ sơ":
      value==4?"Thái độ và kỷ luật không tốt":value==5?"Đang thực thập":value==6?
      "Nhân viên chính thức":value==7?"Đã xem":value==8?"Đợi xếp lịch phỏng vấn"
      :value==9?"Đã kiểm tra đầu vào":value==10?"Giới thiệu doanh nghiệp":"Đã chuyển doanh nghiệp";
      await Promise.all([getTotalRecords(value, status, valueReady), getData(searchText, subjectId, mystatus, 1, pageSize, valueReady)]) 
      setStatus(mystatus); setCurrentPage(1);
    }
    const handleChange2 = (value: number) => {
      let mystatus:string = value==1?"Tất cả":value==2?"Đã đăng ký":value==3?"Đã trả hồ sơ":
      value==4?"Thái độ và kỷ luật không tốt":value==5?"Đang thực thập":value==6?
      "Nhân viên chính thức":value==7?"Đã xem":value==8?"Đợi xếp lịch phỏng vấn"
      :value==9?"Đã kiểm tra đầu vào":value==10?"Giới thiệu doanh nghiệp":"Đã chuyển doanh nghiệp";
      setStatus(mystatus);          
    }
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [optionsUniversity, setOptionsUniversity] = useState<SelectProps['options']>(
      universities.map(item=>{
        return {value: item.matruong, label:item.tentruong}
      })
    );
    const updateCv = async (item: {field:string, value:any}, id:number)=>{
      const token = localStorage.getItem('token');
      const formData = new FormData();
        switch(item.field){
          case 'InternName':
              formData.append('InternName', item.value);
              break;
          case 'DateOfBirth':
              formData.append('DateOfBirth', item.value);
              break;
          case 'TelephoneNum':
              formData.append('TelephoneNum', item.value);
              break;
          case 'InternMail':
              formData.append('InternMail', item.value);
              break;
          case 'InternAddress':
              formData.append('InternAddress', item.value);
              break;
          case 'Major':
              formData.append('Major', item.value);
              break;
          case 'University':
              formData.append('University', item.value);
              break;
          case 'InternSpecialized':
              formData.append('InternSpecialized', item.value);
              break;
          case 'ForeignLanguage':
              formData.append('ForeignLanguage', item.value);
              break;
          default:
              break;
        }
        const config = {     
          headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        }
        axios.put(process.env.REACT_APP_API_URL+'api/Register/'+ id, formData, config)
          .then(response => {
            getData(searchText, subjectId, status, currentPage, pageSize, valueReady);
          })
          .catch(error => {
              console.log(error);
          });
    }
    const [optionsStatus, setOptionsStatus] = useState<SelectProps['options']>([
      {
        value:1, label:"Tất cả"
      },
      {
        value:2, label:"Đã đăng ký"
      },
      {
        value:3, label:"Đã trả hồ sơ"
      },
      {
        value:4, label:"Thái độ và kỷ luật không tốt"
      },
      {
        value:5, label:"Đang thực tập"
      },
      {
        value:6, label:"Nhân viên chính thức"
      },
      {
        value:7, label:"Đã xem"
      },
      {
        value:8, label:"Đợi xếp lịch phỏng vấn"
      },
      {
        value:9, label:"Đã kiểm tra đầu vào"
      },
      {
        value:10, label:"Giới thiệu doanh nghiệp"
      },
      {
        value:11, label:"Đã chuyển doanh nghiệp"
      }
    ]);
    const [fileList, setFileList] = useState<File>();
    const getAllSubjects = async () => {
      const token = localStorage.getItem('token');
      let myOptions:any = [];
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Subject', {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
      }).then(response => {
        if(response.status==401){
          message.info("Phiên đăng nhập đã hết hạn!");
          localStorage.clear();
          window.location.reload();
      }
      return response.json()
      })
      .then((data)=>{
            myOptions.push({value: '-1', label: 'Tất cả'});
            data.map((item:any)=>{
              myOptions.push({value: item.id, label: item.taxCode=='admin@alta.com.vn' ? item.tenmonhoc : item.title})
            })
            setOptions(myOptions);
            console.log(options);
      })
      .catch(err => console.log(err));
    }
    useEffect(()=>{
      connection.start();
      setIsLoading(true);
      const fetchData = async ()=>{
        await Promise.all([
        getTotalRecords(subjectId, status, valueReady),
        getData(searchText, subjectId, status, currentPage, pageSize, valueReady)]);
      }
      fetchData().then(()=>setIsLoading(false));
      getAllSubjects();
      console.log(myMailPattern);
    },[])
    useEffect(()=>{
      connection.on("ReceivedInterns", async (data:any) => {
        let tempData: InternType[] = [];
  await data.map((item:any)=>{
    tempData.push({
      key: item.id,
      id: item.id,
      internName: item.internName,
      internMail:item.internMail,
      dateOfBirth:item.dateOfBirth,
      university: universities.filter(x=>x.matruong==item.university)[0]!=null?
      universities.filter(x=>x.matruong==item.university)[0].tentruong:'',
      fileUrl:item.fileUrl,
      internSpecialized:item.internSpecialized,
      telephoneNum: item.telephoneNum,
      internable: item.internable==1?'Thực tập':'Chính thức',
      fullTime: item.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: item.major,
      registeredDate: item.registeredDate,
      citizenIdentification: item.citizenIdentification,
      citizenIdentificationDate: item.citizenIdentificationDate,
      isEditableInternSpecialized:false,
      isEditableUniversity:false,
      internStatus: item.internStatus,
      yearOfExperiences: 0,
      foreignLanguage: 'IELTS from 8.0',
      entranceTest:item.entranceTest
    })
  })      
  setData(tempData);
    });
    },[data])
  const [activeInternEmail, setActiveInternEmail] = useState('');
  const [isInternDetailOpen, setIsInternDetailOpen] = useState(false);
  const showInternDetail = async (email: string) => {
    return new Promise((resolve)=>{
      setActiveInternEmail(email);
    }) 
    //setIsInternDetailOpen(true);
  }
    const columns: ColumnsType<InternType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          hidden: true,
          key: 'id',
        },
        {
          title: 'Họ và tên',
          dataIndex: 'internName',
          key: 'internName',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <a onClick={()=>{ showInternDetail(record.internMail)
                  setIsInternDetailOpen(true);
                }}>{record.internName}</a>
                <p style={{color:'blue'}}>{record.internStatus}</p>
              </>
            )
          }
        },
        {
          title: 'Ngày sinh',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth'
        },
        {
          title: 'Ngày đăng ký',
          dataIndex: 'registeredDate',
          key: 'registeredDate'
        },
        {
          title: 'Điện thoại',
          dataIndex: 'telephoneNum',
          key: 'telephoneNum'
        },
        {
          title: 'Trường đại học',
          dataIndex: 'university',
          key: 'university',
          width:'350px',
          render:(key:number, record:InternType, index:number) => {
            return(
              <>
                {!record.isEditableUniversity?<a onClick={()=>{
                  let index = data.findIndex(x=>x.id == record.id);
                  data[index].isEditableUniversity = true;
                  let previous = data.slice(0, index);
                  let after = data.slice(index+1);
                  setData([...previous, data[index], ...after ]);
                }}>{record.university}</a>:
                <Select showSearch={true} style={{width:'300px'}}
                optionFilterProp="children"
                filterOption={(input, option:any) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onSelect={async(value)=>{
                  await updateCv({field:'University', value}, record.id);
                  //getData(searchText, subjectId, status, currentPage, pageSize);
                }}
                options={optionsUniversity}
                />
                }
              </>              
            )
          }
        },
        {
          title:'Chuyên ngành',
          dataIndex:'major',
          key: 'major'
        },
        {
          title:'Vị trí ứng tuyển',
          dataIndex:'internSpecialized',
          key:'internSpecialized'
        },
        {
          title:'Hình thức',
          dataIndex: 'internable',
          key: 'internable'
        },
        {
          title: 'Địa chỉ Mail',
          dataIndex: 'internMail',
          key: 'internMail'
        }, 
        {
          title:'Kiểm tra đầu vào',
          dataIndex:'entranceTest',
          key: 'entranceTest'
        },      
        {
            title: 'Download CV',
            dataIndex: 'download',
            key: 'download',
            render: (key:number, record:InternType, text:any) => {
              return(
              <a href='#'
              onClick = {()=>{
                console.log(record.fileUrl);
                const token = localStorage.getItem('token');
                fetch(process.env.REACT_APP_API_URL+'api/Register/files/' + record.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = record.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again
            fetch(process.env.REACT_APP_API_URL+'api/Register/seencv/'+record.id, {
              method: 'PUT',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({id:record.id})
      }).then(response => {
          getData(searchText, subjectId, status, currentPage, pageSize, valueReady);
      })
      .catch(err => console.log(err));         
        });
            }}
              >Tải CV</a>
              ) 
            }
          }
      ].filter(item=>!item.hidden);
    const exportColumn = [
      {
        title: 'Stt',
        dataIndex: 'stt',
        key: 'stt',
      },
      {
        title: 'Mã SV',
        dataIndex: 'masv',
        key: 'masv'
      },
      {
        title: 'Họ lót',
        dataIndex: 'holot',
        key: 'holot'
      },
      {
        title: 'Tên',
        dataIndex: 'ten',
        key: 'ten'
      },
      {
        title: 'Ngày sinh',
        dataIndex: 'ngaysinh',
        key: 'ngaysinh'
      },
      {
        title: 'Giới tính',
        dataIndex: 'gioitinh',
        key: 'gioitinh'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
          title: 'Mã trường',
          dataIndex: 'matruong',
          key: 'matruong'
      }
    ]
    const exportAll = [
      {
        title: 'Stt',
        dataIndex: 'stt',
        key: 'stt',
      },
      {
        title: 'Tên thực tập sinh',
        dataIndex: 'internName',
        key: 'internName'
      },
      {
        title: 'Ngày sinh',
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth'
      },
      {
        title: 'Điện thoại',
        dataIndex: 'telephoneNum',
        key: 'telephoneNum'
      },
      {
        title: 'Trường đại học',
        dataIndex: 'university',
        key: 'university'
      },
      {
        title:'Chuyên ngành',
        dataIndex:'major',
        key: 'major'
      },
      {
        title:'Vị trí ứng tuyển',
        dataIndex:'internSpecialized',
        key:'internSpecialized'
      },
      {
        title:'Hình thức',
        dataIndex: 'internable',
        key: 'internable'
      },
      {
        title: 'Địa chỉ Mail',
        dataIndex: 'internMail',
        key: 'internMail'
      },
      {
        title:'Kiểm tra đầu vào',
        dataIndex:'entranceTest',
        key: 'entranceTest'
      }
    ]
    const handleConfirmOk = () => {
      dataExportAll.map(row=>{
        fetch(process.env.REACT_APP_API_URL+'api/Register/' + row.id, {
          method: 'DELETE',
          headers: {
              'Authorization': `Bearer ${token}`,
          },
  }).then(response => response.json())
  .then(data=>{
    setIsConfirmModalOpen(false);
    getData(searchText,subjectId, status, currentPage, pageSize, valueReady);
  })
  .catch(err => console.log(err));
  });
}
const [value, setValue] = useState(1);
const onChange1 = (e: RadioChangeEvent) => {
  console.log('radio checked', e.target.value);
  setValue(e.target.value);
  setContent(myMailPattern.filter(x=>x.id==e.target.value)[0].content);
  setTitle(myMailPattern.filter(x=>x.id==e.target.value)[0].title);
  setSubject(myMailPattern.filter(x=>x.id==e.target.value)[0].subject);
  setGreeting(myMailPattern.filter(x=>x.id==e.target.value)[0].greeting);
  setFooter(myMailPattern.filter(x=>x.id==e.target.value)[0].footer);
};
const handleConfirmOk1 = () => {
  dataExportAll.map((item:any)=>{
    fetch(process.env.REACT_APP_API_URL+'api/Register/returncv/'+item.id, {
      method: 'PUT',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(status)
}).then(response => response.json())
.then((data) => {
  console.log(data);
})
.catch(err => console.log(err));
  })
  getData(searchText, subjectId, status, currentPage, pageSize, valueReady);
  setIsConfirmModalOpen1(false);
}
      
  const handleConfirmCancel = () => {
    setIsConfirmModalOpen(false);
  }
  
  const handleConfirmCancel2 = () => {
    setIsConfirmModalOpen2(false);
  }

  const handleConfirmCancel1 = () => {
    setIsConfirmModalOpen1(false);
  }
  const sendMailToCandidates = async () => {
    await dataExportAll.map(async (item:ExcelAllType)=>{
      const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Register/SendMail', {
        method: 'POST',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
},
body: JSON.stringify({emailTo: item.internMail, content: content,
  footer:footer, header:item.internName, subject: subject, greeting: greeting,
  title:title
})
}).then(response => response.json())
.then(data => {
  fetch(process.env.REACT_APP_API_URL+'api/Register/returncv/'+item.id, {
    method: 'PUT',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify("Đã trả hồ sơ")
}).then(response => {
message.info("Cập nhật thành công");
})
.catch(err => console.log(err));
})
    })

  }
  const onChangeReady = async (e: RadioChangeEvent) => {
    setIsLoading(true);
      const fetchData = async ()=>{
        await Promise.all([
        getTotalRecords(subjectId, status, e.target.value),
        getData(searchText, subjectId, status, 1, pageSize, e.target.value)]);
      }
      fetchData().then(()=>setIsLoading(false));
      setCurrentPage(1); setValueReady(e.target.value);
  };
  const onFinishImport = async (values: any) => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
      if(fileList)
      {
        formData.append("MyFile",fileList);
        formData.append("CourseName", '');
        formData.append("StartDate", '');
        formData.append("EndDate", '');
        formData.append("SubjectId", '');
        formData.append("FirstDay", '');
        formData.append("SecondDay", '');            
        const  myheaders = { "Content-Type": "multipart/form-data", 'Authorization': `Bearer ${token}` };          
        axios.post(process.env.REACT_APP_API_URL+'api/ImportFromExcel/interns', formData, {
          headers: myheaders, responseType: 'blob'})
          .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'no_imported.csv'); // Specify the file name
          document.body.appendChild(link);
          link.click();
          link.remove();
            getData(searchText,subjectId, status, 1, pageSize, valueReady)
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
          });       
      };
    }
    const expandedRowRender = (record:InternType) => {
      let myInterviews = data.filter(x=>x.id == record.id)[0]?.interviews;
      const columns: ColumnsType<any> = [
        { title: 'Ngày phỏng vấn', dataIndex: 'ngayphongvan', key: 'ngayphongvan'},
        { title: 'Giờ phỏng vấn', dataIndex: 'giophongvan', key: 'giophongvan'},
        { title: 'Kết quả', dataIndex: 'ketqua', key:'ketqua', 
          render: (key:number, row: any, index)=>{
            return(
              <p>{row.ketqua==true?"Đạt":"Không đạt"}</p>
            )
          }
        },
        { title: 'Người phỏng vấn', dataIndex: 'manhansu', key:'manhansu',
          render: (key:number, row: any, index)=>{
            return(
              <p>{admins.filter(x=>x.manhansu==row.manhansu)[0].hovaten}</p>
            )
          }
        },    
      ];
      return (
        <div>
      <p>{record.note}</p>
      <Table columns={columns} dataSource={myInterviews} />
      </div>
    )
    }
  const [searchText, setSearchText] = useState("___");
  const onSearch: SearchProps['onSearch'] = async (value:string, _e:React.ChangeEvent<HTMLInputElement> | 
    React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLInputElement> | undefined) => {
      setSearchText(value!=""?value:"___");
      await getTotalRecords(subjectId, status, valueReady);
      getData(value!=""?value:"___", subjectId, status, 1, pageSize, valueReady);
      setCurrentPage(1);
    };
    return(
    <>
    {localStorage.getItem('userRole')=="SupAdmin"?
    <Form onFinish={onFinishImport}
    disabled = { isDisabled }
    layout="inline"
    >
      <Form.Item name="fileList">
        <Input type="file" onChange={(e:ChangeEvent<HTMLInputElement>)=>{
          const temp = e.target.files;
          if(!temp) 
            return;
          else{
            setFileList(temp[0]);
          }            
          }}/>
      </Form.Item>    
      <Form.Item label="">
          <Button type="primary" onClick={()=>setIsLoading(true)} htmlType="submit">Import</Button>
      </Form.Item>
    </Form>:null}
    <Radio.Group onChange={onChangeReady} value={valueReady}>
          <Radio value={0}>Tất cả</Radio>
          <Radio value={1}>Đã sẵn sàng nhận việc</Radio>
          <Radio value={2}>Chưa sẵn sàng nhận việc</Radio>
    </Radio.Group>
    <Space direction="horizontal">
    <Search placeholder="Nhập tên ứng viên để tìm" onSearch={onSearch} style={{ width: '300px' }} />
    {isExportExcel?
      <a href="#" style={{fontSize:'16px'}}
      onClick={()=> {
        const excel = new Excel();
        excel.addSheet(courseCode).addColumns(exportColumn).addDataSource(dataExport, {
      str2Percent: true
    })
    .saveAs(courseCode + ".xlsx");
        return false;
      }}
      >Tạo nhóm thực tập</a>
      : <p></p>}
      &nbsp;Lĩnh vực thực tập: &nbsp;
      <Select
        onChange={handleChange}
        options={options}
        style={{width:'300px'}}
        /> </Space> <br/>
        Trạng thái:&nbsp;
      <Select
        onChange={handleChange1}
        options={optionsStatus}
        style={{width:'300px'}}
        /> &nbsp;
      <a href="#" style={{fontSize:'16px'}}
      onClick={()=> {
        const excel = new Excel();
        excel.addSheet('MyFile').addColumns(exportAll).addDataSource(dataExportAll, {
      str2Percent: true
    })
    .saveAs("MyFile.xlsx");
        return false;
      }}
      >Xuất excel</a> &nbsp;
      <Button type="primary" danger
      disabled = {localStorage.getItem('userRole') == 'SupAdmin' ? 
      false : true } 
            onClick = {()=>{
              setIsConfirmModalOpen(true);
            }}
            >Xóa</Button>
      <Button type="primary"
      disabled = {localStorage.getItem('userRole') == 'SupAdmin' ? 
      false : true } 
            onClick = {async()=>{
                  setIsConfirmModalOpen2(true);   
            }}
            >Thư cám ơn</Button>
            <Button type="primary"
            disabled = {localStorage.getItem('userRole') == 'SupAdmin' ? 
            false : true }
            onClick = {()=>{
              setIsConfirmModalOpen1(true);
            }}>Chuyển trạng thái</Button>
     <Table columns={columns} dataSource={data} pagination={customPagination}
     expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
     scroll={{ x: 1800, y: 500 }}
     rowSelection={{
      type: 'checkbox', 
      ...rowSelection,
    }}
     style={{marginTop:10,width:'160%'}} />
    <Modal title="Xóa dữ liệu" open={isConfirmModalOpen} onOk={()=>handleConfirmOk()} onCancel={handleConfirmCancel}>
        <h3 style={{color:'#FF0000'}}>Bạn có chắc chắn xóa các hồ sơ này khỏi hệ thống?</h3>
    </Modal>
    <Modal title="Chuyển trạng thái" open={isConfirmModalOpen1} onOk={()=>handleConfirmOk1()} onCancel={handleConfirmCancel1}>
    <Select
        onChange={handleChange2}
        options={optionsStatus}
        style={{width:'80%'}}
        />
    </Modal>
    <Modal title="Chọn biểu mẫu" open={isConfirmModalOpen2} onOk={()=>sendMailToCandidates()} onCancel={handleConfirmCancel2}>
    <Radio.Group onChange={onChange1} value={value}>
      <div style={{height:'300px', overflow:'scroll'}}>
      <Space direction="vertical">
        {myMailPattern.map((item:any)=>{
          return(
            <Radio value={item.id}>
              <a>{item.anhminhhoa!=null?item.anhminhhoa:'Biểu mẫu'}</a></Radio>
          )
        })}
      </Space>
      </div>
    </Radio.Group>
    </Modal>
    <Modal title="Loading" open={isLoading} closeIcon={null} footer={null}>
      <div className='LoadingContainer'>
        <img src='./images/loading.gif' />
      </div>
    </Modal>
    <Modal title="Chi tiết ứng viên" footer={null} open={isInternDetailOpen} 
    onCancel={()=>setIsInternDetailOpen(false)} width="auto" // Set width to auto
    className="custom-modal" >
       <EditCv emailData={activeInternEmail} readOnlyOption={true} handleCallBack={()=>{}} />
    </Modal>
    </>
    )
}
export default InternsRegistered;