import React, {useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { answerSlice } from '../thitracnghiem/tracnghiemSlice';
import { useAppDispatch } from '../../configs/hook';

import './styles.css';
type SingleResponseProps = {
    dapan:any,
    index: number,
    phatbieu: string,
    imageDataUrl: string
    luachons: {
      luachonId: number,
      cauhoiId: number,
      phatbieu: string
    }[]
  }
const MultipleResponse = (props:SingleResponseProps) => {
  const myAnswers = useSelector((state:RootState)=>state.answer);
  const dispatch = useAppDispatch();
  return (
    <div className='whole-question'>
    <div className="question-container">
      <div className="question-header">
        Câu {props.index}:
      </div>
      <div className="question-content">
      {ReactHtmlParser(props.phatbieu)}
      </div>
      <div className="answer-list">
      {props.luachons.map((item:any, index:number)=>{
            return(
              <div className="answer-item" key={item.luachonId} style={props.dapan.length>0?
                props.dapan.filter((x:any)=>x.luachonId==item.luachonId)[0]?.dapan == true?
                {color:'blue'} : {color:'red'} : {}}>
                  <input type="checkbox" name="answer"
                  onChange={async(e)=>{ 
                    let rowIndex = myAnswers.findIndex(x=>x.cauhoiId==item.cauhoiId);
                    let answer = {...myAnswers[rowIndex], luachons:[...myAnswers[rowIndex].luachons]}
                    if(e.target.checked){                      
                      answer.luachons.push({luachonId: item.luachonId,
                        cauhoiId: item.cauhoiId, phatbieu: item.phatbieu})                     
                    }
                    else
                    {                      
                      answer.luachons.splice(answer.luachons.findIndex(x=>x.luachonId==item.luachonId), 1);                     
                    }
                    dispatch(answerSlice.actions.changeAnswer(answer));
                }}
                  />
                  {index+1}. {ReactHtmlParser(item.phatbieu)}.
              </div>
            )
        })}
      </div>
    </div>
    {props.imageDataUrl!=""&&props.imageDataUrl!=null?
          <img src={props.imageDataUrl} style={{objectFit:'contain', maxWidth:'100%', 
          paddingRight:'30px', flex:1}} />
          : ''}
  </div>
  );
};

export default MultipleResponse;
