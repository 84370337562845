import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Collapse, message, SelectProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Excel } from "antd-table-saveas-excel";
import { CompanyType, InternType, Monhoc } from '../../interface';
import {FindCandidate} from '../internsRegistered/findCandidate';
import { RootState } from '../../redux/store';
const { Panel } = Collapse;
const Company: React.FC = () => {
    const [jdUrl, setJdUrl] = useState('');
    const [parentId,setParentId]=useState(-1);
    const token = localStorage.getItem('token');
    const [monhocId, setMonhocId] = useState(0);
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const universities = useSelector((state:RootState)=>state.university);
    const [pageSize, setPageSize] = useState(10); // State to manage page size
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // State to manage current page
    const [deleteItem, setDeleteItem] = useState(''); 
    const [isConfirmModalResetPassOpen, setIsConfirmModalResetPassOpen] = useState(false)
    const [taxCode, setTaxCode] = useState('');
    const [internsForSubjectId, setInternsForSubjectId] = useState<InternType[]>([]);
    const listSubjects = useSelector((state:RootState)=>state.subject);
    const provinceList = useSelector((state:RootState)=>state.provinces);
    const getInternsBySubjectId = async (id:number) => {
      let status = 'Tất cả';
      await fetch(process.env.REACT_APP_API_URL+"api/Register/internbystatus/" + status + "/" + id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(async(data)=>{
          setTotalItems(data);
      })
      await fetch(process.env.REACT_APP_API_URL+"api/Register/internbystatus/___/" + status + "/" + id
      + "/" + currentPage + "/" + pageSize, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
}).then(response => response.json())
.then(async(data)=>{
  let tempData: InternType[] = [];
  await data.map((item:any)=>{
    tempData.push({
      key: item.id,
      id: item.id,
      internName: item.internName,
      internMail:item.internMail,
      dateOfBirth:item.dateOfBirth,
      university: universities.filter(x=>x.matruong==item.university)[0]!=null?
      universities.filter(x=>x.matruong==item.university)[0].tentruong:'',
      fileUrl:item.fileUrl,
      internSpecialized:item.internSpecialized,
      telephoneNum: item.telephoneNum,
      internable: item.internable==1?'Thực tập':'Chính thức',
      fullTime: item.fullTime==1?'Toàn thời gian':'Bán thời gian',
      major: item.major,
      registeredDate: item.registeredDate,
      citizenIdentification: item.citizenIdentification,
      citizenIdentificationDate: item.citizenIdentificationDate,
      isEditableInternSpecialized:false,
      internStatus: item.internStatus,
      yearOfExperiences: 0,
      foreignLanguage: 'IELTS from 8.0',
      entranceTest: item.entranceTest,
      hiddenToEnterprise: item.hiddenToEnterprise
    })
  })      
  setInternsForSubjectId(tempData);            
})
.catch(err => console.log(err));
    }
    const columns: ColumnsType<Monhoc> = [
        {
          title:'Id',
          dataIndex:'id',
          key:'id',
        },
        {
          title: 'Lĩnh vực tuyển dụng',
          dataIndex: 'tenmonhoc',
          key: 'tenmonhoc',
        },
        {
          title: 'Vị trị tuyển dụng',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Nơi làm việc',
          dataIndex: 'requirement',
          key: 'requirement'
        },
        {
          title: 'Download JD',
          dataIndex: 'downloadJd',
          key: 'downloadJd',
          render:(key:number, record: Monhoc, index) => {
            return(
              <a href="#" onClick={()=>{
                fetch(process.env.REACT_APP_API_URL+'api/Subject/files/' + record.id.toString(), {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
            return response.blob()
            })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            setJdUrl(url);
        }).then(()=>{
          window.open(jdUrl, '_blank')?.focus();
        });     
              }}>Download JD</a>
            )
          }
        },
        {
          title:'Tiện ích',
          dataIndex:'action',
          key: 'action',
          render: (key:any, record:Monhoc, index) => {
            return(
              <Button type='primary' onClick={()=>{
                setId(record.id);
                setTaxCode(record.taxCode);
                setParentId(listSubjects?.filter(x=>x.abbreation==record.abbreation)[0].id);
                setIsFindCandidateModalOpen(true)
              }} >Tìm ứng viên</Button>
            )
          }
        }
      ];
      const columnsExcel = [
        {
            title: 'Email đại diện',
            dataIndex: 'taxCode',
            key: 'taxCode',
          },
          {
            title: 'Tên công ty',
            dataIndex: 'companyName',
            key: 'companyName'
          },
          {
            title: 'Địa chỉ văn phòng',
            dataIndex: 'companyAddress',
            key: 'companyAddress',
          },
          {
            title: 'Điện thoại liên hệ',
            dataIndex: 'telephone',
            key: 'telephone'
          }
      ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFindCandidateModalOpen, setIsFindCandidateModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false); 
    const [id, setId] = useState(-1);
    const handleConfirmOk = async (item: string) => {
      if(item!=''){
        const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Company/'+ item, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          },
        });
        if(rawResponse.status==401){
          message.info("Phiên đăng nhập đã hết hạn");
          localStorage.clear();
          window.location.reload();
        }
        const msg = await rawResponse.json();
        message.info(msg);
      }
      setIsConfirmModalOpen(false);
    }
    const handleConfirmOkResetPass = async(item:string)=>{
        const token = localStorage.getItem('token');
              const rawResponse = fetch(process.env.REACT_APP_API_URL+'api/User', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                body: JSON.stringify({role:"5", userName:item, oldPassword:"", 
                newPassword:'abc123!', mustChangeAtFirstTime: true})
            }).then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
            return response.json()
            })
            .then((data)=>{
                  message.info("Mật khẩu đã được reset");
            })
            .catch(err => console.log(err));
            setIsConfirmModalResetPassOpen(false);
    }
    const handleConfirmCancel = () => {
      setIsConfirmModalOpen(false);
      setIsConfirmModalResetPassOpen(false);
    }
    const handleConfirmResetPassCancel = () => {
      setIsConfirmModalResetPassOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showModal = () => {
        setIsModalOpen(true);
      };

    const [data, setData] = useState<CompanyType[]>([]);
    const getAllUniveristy = async () => {
        await fetch(process.env.REACT_APP_API_URL+'api/Company/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
    }).then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
    .then((data)=>{      
      const transformedData = data.map((item:any) => ({
        ...item,
        monhocs: item.monhocs.map((subitem:any) => {
          let myItems = subitem.requirement.split("___");
          myItems.splice(myItems.findIndex((x:any)=>x==''), myItems.findIndex((x:any)=>x=='')+1);
          let cities = '';
          for(let i=0; i<myItems.length; i++){
            cities += provinceList?.filter(x=>x.value==myItems[i])[0]?.label+';'
          }      
            return(
              {id:subitem.id, tenmonhoc: subitem.tenmonhoc, abbreation:subitem.abbreation, title: subitem.title,
              requirement:cities, key: subitem.id, taxCode: subitem.taxCode}
            )
        })}));
      setData(transformedData);     
    })
    .catch(err => console.log(err));
    } 
    const handlePageSizeChange = (current:number, size:number) => {
      setPageSize(size);
    };
    const handlePageChange = (page:number, pageSize:number) => {
      setCurrentPage(page);
    };
    const expandedRowRender = (record:Monhoc) => {     
      const columns1: ColumnsType<InternType> = [
        {
          title: 'Id',
          dataIndex: 'id',
          hidden: true,
          key: 'id',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.id}</p>
              </>
            )
          }
        },
        {
          title: 'Tên thực tập sinh',
          dataIndex: 'internName',
          key: 'internName',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.internName}</p>
              </>
            )
          }
        },
        {
          title: 'Ngày sinh',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.dateOfBirth}</p>
              </>
            )
          }
        },
        {
          title: 'Ngày đăng ký',
          dataIndex: 'registeredDate',
          key: 'registeredDate',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.registeredDate}</p>
              </>
            )
          }
        },
        {
          title: 'Điện thoại',
          dataIndex: 'telephoneNum',
          key: 'telephoneNum',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.telephoneNum}</p>
              </>
            )
          }
        },
        {
          title: 'Trường đại học',
          dataIndex: 'university',
          key: 'university',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.university}</p>
              </>
            )
          }
        },
        {
          title:'Chuyên ngành',
          dataIndex:'major',
          key: 'major',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.major}</p>
              </>
            )
          }
        },
        {
          title: 'Địa chỉ Mail',
          dataIndex: 'internMail',
          key: 'internMail',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.internMail}</p>
              </>
            )
          }
        }, 
        {
          title:'Kiểm tra đầu vào',
          dataIndex:'entranceTest',
          key: 'entranceTest',
          render:(key:number, record:InternType, text:any)=>{
            return(
              <>
                <p style={record.hiddenToEnterprise||null?{color:'gray',fontWeight:200}:undefined}>{record.entranceTest}</p>
              </>
            )
          }
        },      
        {
            title: 'Download CV',
            dataIndex: 'download',
            key: 'download',
            render: (key:number, record:InternType, text:any) => {
              return(
              <a href='#'
              onClick = {()=>{
                console.log(record.fileUrl);
                const token = localStorage.getItem('token');
                fetch(process.env.REACT_APP_API_URL+'api/Register/files/' + record.fileUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
            })
            .then(response => {
              if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
            return response.blob()
            })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = record.fileUrl;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
            }}
              >Tải CV</a>
              ) 
            }
          },
          {
            title:'Gởi doanh nghiệp',
            dataIndex:'sendToEnterprise',
            key: 'sendToEnterprise',
            render:(key:number, record:InternType, text:any) => {
              return(
                <Button onClick={()=>{
                  const token = localStorage.getItem('token');
                  fetch(process.env.REACT_APP_API_URL+'api/Register/sendtoenterprise/' + record.id, {
                      method: 'PUT',
                      headers: {
                          'Authorization': `Bearer ${token}`,
                      },
              }).then(response=>{if(response.status==401){
                message.info("Phiên đăng nhập đã hết hạn!");
                localStorage.clear();
                window.location.reload();
            }
            return response.json()})
              .then(data=>{
                getInternsBySubjectId(monhocId);
              });
                }}>{record.hiddenToEnterprise||null?'Gởi':'Thu hổi'}</Button>
              )
            }
          }
      ].filter(item=>!item.hidden);
      return <Table columns={columns1} dataSource={internsForSubjectId} 
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: totalItems,
        showSizeChanger: true, // Show rows per page selector
        onChange: handlePageChange,
        onShowSizeChange: handlePageSizeChange,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '20', '50', '100'] // Customizable options for rows per page
      }}
      />
    }
    const handlePanelChange = (key:string|string[]) => {
          
    };
    useEffect(()=>{
      const getJobPosion = async () => {
        await getAllUniveristy();
      }
      getJobPosion();      
  },[])
    return(
    <>
    <Collapse accordion onChange={handlePanelChange}>
    {data.map((item)=>{
      return(
    <Panel header={<div>
            {item.companyName} - {item.telephone} - <a href='#'
            onClick={()=>{setIsConfirmModalOpen(true);
            setDeleteItem(item.taxCode);
            }}
            >Xóa</a>&nbsp;&nbsp;
            <a href='#'
            onClick={()=>{setIsConfirmModalResetPassOpen(true);
                setDeleteItem(item.taxCode);
            }}
            >Khởi tạo mật khẩu</a>
          </div>
          } key={item.taxCode}>
    <Table columns={columns} dataSource={item.monhocs}
    expandable={{ expandedRowRender, expandedRowKeys:expandedRowKeys, 
      onExpand:(expanded:boolean, record:Monhoc)=>{
        setMonhocId(record.id);
      getInternsBySubjectId(record.id);
      setExpandedRowKeys(expanded?[record.key as React.Key]:[]);
    }}}
     />
     </Panel>)
    })}
    </Collapse>
    <Modal title="Deleting confirmation" open={isConfirmModalOpen} onOk={()=>handleConfirmOk(deleteItem)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', overflow:'hidden'}}>
            <img src={'recyclebin.png'} style={{width:'100px', height:'80px'}} />
          </div>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
            Are you sure to delete this item?
          </div>
        </div>
      </Modal>
      <Modal title="Xác nhận khởi tạo lại mật khẩu" open={isConfirmModalResetPassOpen} onOk={()=>handleConfirmOkResetPass(deleteItem)} onCancel={handleConfirmCancel}>
        <div style={{height:'110px', marginTop:'20px'}}>
          <div style={{float:'left', fontSize:'24px', paddingLeft:'10px', color:'red'}}>
            Bạn có chắc chắn đặt lại mật khẩu cho doanh nghiệp này về abc123!?
          </div>
        </div>
      </Modal>
      <Modal title="Tìm ứng viên cho vị trí tuyển dụng" open={isFindCandidateModalOpen} 
      onOk={()=>setIsFindCandidateModalOpen(false)} onCancel={()=>setIsFindCandidateModalOpen(false)}
      width={1000}
      >      
        <FindCandidate id = {id} taxCode={taxCode} parentId={parentId} />
      </Modal>
    </>
    )
}

export default Company;