import {createSlice, isFulfilled, PayloadAction} from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import {message} from 'antd';
import { useAppDispatch } from '../../configs/hook';
import { Answer } from '../../interface';
import getState from 'redux-thunk';
import {Dethi, AnswerRequest, LuachonAnswer, Subject} from '../../interface';
import { stat } from 'fs';

const initialData: Dethi =  {
    madethi: "000001",
    tenmonhoc: "OOP",
    socauhoi: 5,
    ketqua: "",
    cauhois: []
  }
type ChoiceProps = {
  luachonId: number,
  phatbieu: string
} 
const tracnghiemSlice = createSlice({
    name: 'tracnghiem',
    initialState:{} as Dethi,
    reducers: {
        generateDethi: (state:Dethi, action:PayloadAction<Dethi>) => {
          action.payload.cauhois.forEach((item, i)=>{
            if(item.luachons.length == 1)
              item.luachons[0].phatbieu = "";
          })
          return action.payload;
        },
        getKetqua:(state:Dethi, action:PayloadAction<string>)=>{
          state.ketqua = action.payload;
            return state;
        },
        updateStatus: (state:Dethi, action:PayloadAction<LuachonAnswer>) =>{
          console.log(action.payload.luachonId);
          const myCauhoi = state.cauhois.find(a=>a.cauhoiId==action.payload.cauhoiId);
          if(myCauhoi?.nhieuluachon==false){
              const myUpdateItem = myCauhoi?.luachons.find(c=>c.luachonId==c.luachonId);
              const others = myCauhoi?.luachons.find(c=>c.luachonId!=c.luachonId);
              if(myUpdateItem)
                  myUpdateItem.checkStatus = true;
              if(others)
                  others.checkStatus = false;
          }    
          return state;
        },
        updatePhatbieu: (state:Dethi, action: PayloadAction<ChoiceProps>) => {
            let cauhoiIndex = -1;
            console.log(action.payload);
            state.cauhois.forEach((element:any, i) => {
              element.luachons.forEach((row:any)=>{
                if(row.luachonId == action.payload.luachonId)
                  cauhoiIndex = i;
              })
            })
            if(cauhoiIndex>-1){
              // let previous = state.cauhois.slice(0, cauhoiIndex);
              // let next = state.cauhois.slice(cauhoiIndex+1);
              state.cauhois[cauhoiIndex].luachons[0].phatbieu = action.payload.phatbieu;
              //return {...state, cauhois: [...previous, state.cauhois[cauhoiIndex], ...next]}
            }
            return state;
        }
    }
})

export const answerSlice = createSlice({
  name: 'answer',
  initialState: [] as Answer[],
  reducers:{
    getAnswer: (state:Answer[], action: PayloadAction<Answer[]>)=>{
        return action.payload;
    },
    changeAnswer: (state:Answer[], action: PayloadAction<Answer>)=>{
      let index = state.findIndex(x=>x.cauhoiId == action.payload.cauhoiId);
      if(index>-1){
          let previous = state.slice(0, index);
          let after = state.slice(index+1);
          return [...previous, action.payload, ...after];
      }
      return state;
    }
  }
})

export const dapanSlice = createSlice({
  name: 'dapan',
  initialState: [] as any[],
  reducers:{
    getDapan: (state, action: PayloadAction<any[]>)=>{
      return action.payload;
    }
  }
})

export function getDapan(myQuestions:any){
  return async function getDapanThunk(dispatch: Dispatch, getState:any){
    const token = localStorage.getItem('token');
    let correctAnswers:any[] = [];
    for(let i=0; i<myQuestions.cauhois.length; i++){
      await fetch(process.env.REACT_APP_API_URL+"api/Dethi/dapan/"
      + myQuestions.cauhois[i].cauhoiId,{
       method: 'GET',
         headers: {
           'Accept': 'application/json',
           'Content-type': 'application/json',
         }
     })
       .then(response => response.json())
       .then((data)=>{
         correctAnswers.push(...data);
       })
       .catch(err => console.log(err));
    }
    console.log(correctAnswers);
    dispatch(dapanSlice.actions.getDapan(correctAnswers));
  }
}
export function getDethi(subjectId: Subject){
    return async function getDethiThunk(dispatch: Dispatch, getState:any){
    let myDethi: Dethi;
    const studentCode = localStorage.getItem('userNameData');
    const token = localStorage.getItem('token');
    const roleData = localStorage.getItem('roleData');
    console.log(subjectId);
    const callApi = roleData=='4' ? await fetch(process.env.REACT_APP_API_URL+"api/Dethi/" + subjectId.numberOfQuestions + 
    "/" + subjectId.subjectId +
    "/" + studentCode + "/" + subjectId.malophocphan + '/0.3/0.3',{
      method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        },
    })
    .then(response => response.json())
      .then((data)=>{
        if(data.message == null){
          localStorage.setItem("message", "");
          dispatch(
            tracnghiemSlice.actions.generateDethi(
                {madethi: data.madethi,
                    tenmonhoc:  data.tenmonhoc,
                    socauhoi: data.socauhoi,
                    ketqua: "",
                    cauhois : data.cauhois.map((item:any)=>{
                        return {cauhoiId: item.cauhoiId,
                            phatbieu: item.phatbieu,
                            hinhanh: item.hinhanh,
                            nhieuluachon:item.nhieuluachon,
                            loaicauhoi:item.loaicauhoi,
                            imageDataUrl: item.imageDataUrl,
                            luachons: 
                            item.luachons.map((row:any)=>{
                                return {
                                    luachonId: row.luachonId,
                                    cauhoiId: row.cauhoiId,
                                    phatbieu: row.phatbieu,
                                    checkStatus: false
                                }
                            })
                        } 
                    })
                    }
            )
          )
          dispatch(answerSlice.actions.getAnswer(
            data.cauhois.map((item:any)=>{
            return({
              cauhoiId: item.cauhoiId,
              nhieuluachon: item.nhieuluachon,
              phatbieu: item.phatbieu,
              hinhanh: "",
              loaicauhoi: 0,
              shortAnswer: item.luachons.length!=1?false:true,
              luachons: item.luachons.length!=1?[]:[{luachonId: item.luachons[0].luachonId,
                cauhoiId: item.cauhoiId, phatbieu: ''}]
            })
            })
          ))
        }
        else
        {
          localStorage.setItem("message", data.message);
        }       
      })
      .catch(err => console.log(err))
    : await fetch(process.env.REACT_APP_API_URL+"api/Dethi/" + subjectId.numberOfQuestions + 
    "/" + subjectId.subjectId +
    "/" + studentCode + "/" + subjectId.malophocphan + '/0.3/0.3',{
      method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        },
    })
    .then(response => {
      if(response.status==401){
        message.info("Phiên đăng nhập đã hết hạn!");
        localStorage.clear();
        window.location.reload();
    }
    return response.json()
    })
      .then((data)=>{
        console.log(data);
        if(data.message == null){
          localStorage.setItem("message", "");
          dispatch(
            tracnghiemSlice.actions.generateDethi(
                {madethi: data.madethi,
                    tenmonhoc:  data.tenmonhoc,
                    socauhoi: data.socauhoi,
                    ketqua: "",
                    cauhois : data.cauhois.map((item:any)=>{
                        return {cauhoiId: item.cauhoiId,
                            phatbieu: item.phatbieu,
                            hinhanh: item.hinhanh,
                            nhieuluachon:item.nhieuluachon,
                            loaicauhoi:item.loaicauhoi,
                            imageDataUrl: item.imageDataUrl,
                            luachons: 
                            item.luachons.map((row:any)=>{
                                return {
                                    luachonId: row.luachonId,
                                    cauhoiId: row.cauhoiId,
                                    phatbieu: row.phatbieu,
                                    checkStatus: false
                                }
                            })
                        } 
                    })
                    }
            )
          )
          dispatch(answerSlice.actions.getAnswer(
            data.cauhois.map((item:any)=>{
            return({
              cauhoiId: item.cauhoiId,
              nhieuluachon: item.nhieuluachon,
              phatbieu: item.phatbieu,
              hinhanh: "",
              loaicauhoi: 0,
              shortAnswer: item.luachons.length!=1?false:true,
              luachons: item.luachons.length!=1?[]:[{luachonId: item.luachons[0].luachonId,
                cauhoiId: item.cauhoiId, phatbieu: ''}]
            })
            })
          ))
        }
        else
        {
          localStorage.setItem("message", data.message);
        }       
      })
      .catch(err => console.log(err));
    }
    console.log(getState);
}
export function submitBailam(answerRequest: AnswerRequest){
  return async function submitBailamThunk(dispatch: Dispatch, getState:any){
    const token = localStorage.getItem('token');
    const rawResponse = await fetch(process.env.REACT_APP_API_URL+'api/Dethi', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(answerRequest)
    });
    const content = await rawResponse.json();
    dispatch(tracnghiemSlice.actions.getKetqua(content.message));
    console.log(content);
  }
}

export default tracnghiemSlice;